import * as React from 'react';
import { IAuth, AuthContextType } from '../@types/user/index.d';
import Cookies from 'js-cookie';
import { default as Dayjs } from 'dayjs';
import { Regex } from '@/utils';

export const AuthContext = React.createContext<AuthContextType | null>(null);

const domain = import.meta.env.VITE_APP_DOMAIN;
const expires = 20 / 60 / 24;

interface Props {
    children: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ children }) => {
    const cookieValue = Cookies.get('expjwt');
    const cookieType = Cookies.get('type');

    const [auth, setAuth] = React.useState<IAuth>({
        token: cookieValue ? cookieValue : '',
        type: cookieType || '1',
    });

    const handleChangeType = (value: string) => {
        const DataToken = {
            token: auth.token,
            type: value,
        };

        Cookies.set('type', value, {
            domain,
            expires,
        });

        setAuth(DataToken);
    };

    const handlerAuthUser = (DataToken: { token: string; type: string }) => {
        if (DataToken) {
            const cookieExpirationTime = Dayjs()
                .add(10, 'minutes')
                .toISOString();

            if (Regex.date.test(cookieExpirationTime)) {
                Cookies.set('cookieExpiration', cookieExpirationTime, {
                    expires,
                    domain,
                });
            } else {
                const newDate = new Date();
                newDate.setMinutes(newDate.getMinutes() + 8);

                Cookies.set('cookieExpiration', newDate.toISOString(), {
                    expires,
                    domain,
                });
            }
            if (DataToken.type == '')
                DataToken.type = Cookies.get('type') || '1';
            Cookies.set('expjwt', DataToken.token, { domain, expires });
            Cookies.set('type', DataToken.type, {
                domain,
                expires,
            });
            if (Cookies.get('admin_session')) {
                Cookies.set('admin_session', DataToken.token, {
                    domain,
                    expires,
                });
            }

            if (DataToken.type == '2') {
                Cookies.set('development', 'true', {
                    expires,
                    domain,
                });
            }
            setAuth(DataToken);
        } else {
            setAuth({ token: '', type: '' });
            Cookies.remove('expjwt', { domain });
            Cookies.remove('userView', { domain });
            Cookies.remove('type', { domain });
            Cookies.remove('cookieExpiration', { domain });
        }
    };

    const handlerLogout = async () => {
        setAuth({ token: '', type: '' });

        try {
            sessionStorage.clear();
            Cookies.remove('expjwt', { domain });
            Cookies.remove('type', { domain });
            Cookies.remove('cookieExpiration', { domain });
            Cookies.remove('userView', { domain });

            document.cookie =
                'expjwt' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
            document.cookie =
                'type' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
            document.cookie =
                'cookieExpiration' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
            document.cookie =
                'userView' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
        } catch (_) {
            document.cookie =
                'expjwt' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
            document.cookie =
                'type' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
            document.cookie =
                'cookieExpiration' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
            document.cookie =
                'userView' +
                '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=' +
                domain;
        }
    };

    const data = { auth, handlerAuthUser, handlerLogout, handleChangeType };

    return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
